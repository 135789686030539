<template>
	<div class="footer">
		<div class="footer-top">
			<div class="nav-list">
				<div class="nav" v-for="item in navList">
					<a href="#" class="nav-title">{{item.title}}</a>
					<a href="#" v-for='ita in item.list'>{{ita.nav}}</a>
				</div>
			</div>
			<div class="contact-list">
				<div class="contact">
					<div class="contact-top">
						<img src="../assets/image/phone.png">
						<p>全国咨询热线</p>
					</div>
					<h4>400-676-5955</h4>
				</div>
				<div class="contact">
					<div class="contact-top">
						<img src="../assets/image/map1.png">
						<p>公司地址</p>
					</div>
					<p>云南省昆明市西山区环城西路565号</p>
				</div>
			</div>
			<div class="code-list">
				<div class="code">
					<img src="../assets/image/app.png">
					<p>微信公众号</p>
				</div>
				<div class="code">
					<img src="../assets/image/app.png">
					<p>手机版</p>
				</div>
				<div class="code">
					<img src="../assets/image/xiaochengxu.png">
					<p>小程序</p>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			<p>版权所有：Copyright © 2017云南爱尔信教育科技股份有限公司 All rights reserved.Copyright© 云南爱尔信教育科技股份有限公司 滇ICP备15000915号 电信业务审批:滇B2-20180017</p>
		</div>
	</div>
</template>

<script>
	export default {
		name:'Footer',
		data(){
			return{
				navList:[
					{
						title:'集团简介',
						url:'#',
						list:[
							{
								nav:'企业文化',
								url:'#'
							},
							{
								nav:'发展历程',
								url:'#'
							},
							{
								nav:'组织架构',
								url:'#'
							},
							{
								nav:'荣誉资质',
								url:'#'
							}
						]
					},
					{
						title:'集团简介',
						url:'#',
						list:[
							{
								nav:'企业文化',
								url:'#'
							},
							{
								nav:'发展历程',
								url:'#'
							},
							{
								nav:'组织架构',
								url:'#'
							},
							{
								nav:'荣誉资质',
								url:'#'
							}
						]
					},
					{
						title:'集团简介',
						url:'#',
						list:[
							{
								nav:'企业文化',
								url:'#'
							},
							{
								nav:'发展历程',
								url:'#'
							},
							{
								nav:'组织架构',
								url:'#'
							},
							{
								nav:'荣誉资质',
								url:'#'
							}
						]
					},
					{
						title:'集团简介',
						url:'#',
						list:[
							{
								nav:'企业文化',
								url:'#'
							},
							{
								nav:'发展历程',
								url:'#'
							},
							{
								nav:'组织架构',
								url:'#'
							},
							{
								nav:'荣誉资质',
								url:'#'
							}
						]
					},
					{
						title:'集团简介',
						url:'#',
						list:[
							{
								nav:'企业文化',
								url:'#'
							},
							{
								nav:'发展历程',
								url:'#'
							},
							{
								nav:'组织架构',
								url:'#'
							},
							{
								nav:'荣誉资质',
								url:'#'
							}
						]
					},
					{
						title:'集团简介',
						url:'#',
						list:[
							{
								nav:'企业文化',
								url:'#'
							},
							{
								nav:'发展历程',
								url:'#'
							},
							{
								nav:'组织架构',
								url:'#'
							},
							{
								nav:'荣誉资质',
								url:'#'
							}
						]
					},
					{
						title:'集团简介',
						url:'#',
						list:[
							{
								nav:'企业文化',
								url:'#'
							},
							{
								nav:'发展历程',
								url:'#'
							},
							{
								nav:'组织架构',
								url:'#'
							},
							{
								nav:'荣誉资质',
								url:'#'
							}
						]
					}
				]
			}
		}
	}
</script>

<style>
	.footer{
		position: relative;
		background-color: #FBFBFB;
	}
	.footer-top{
		width: 1600px;
		margin: 0 auto;
		padding-top: 60px;
		padding-bottom: 20px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	.nav-list{
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}
	.nav{
		display: flex;
		flex-direction: column;
		margin-right: 40px;
	}
	.nav a{
		font-size: 14px;
		color: #303133;
		margin-bottom: 20px;
	}
	.nav-title{
		font-size: 20px !important;
		color: #303133;
	}
	.contact{
		margin-right: 40px;
		text-align: left;
	}
	.contact-top{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 10px;
	}
	.contact-top img{
		width: 40px;
		height: 40px;
		margin-right: 15px;
	}
	.contact p{
		font-size: 14px;
		color: #303133;
	}
	.contact h4{
		font-size: 36px;
		color: #003A97;
		margin: 15px 0;
	}
	.code-list{
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
	}
	.code{
		margin-left: 20px;
		text-align: center;
	}
	.code img{
		width: 113px;
		height: 113px;
		margin-bottom: 15px;
	}
	.code p{
		font-size: 14px;
		color: #303133;
	}
	.footer-bottom{
		height: 42px;
		line-height: 42px;
		text-align: center;
		font-size: 14px;
		color: #666;
		background: rgba(230,230,230,1);
	}
</style>
