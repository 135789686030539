<template>
	<div>
		<Header></Header>
		<div class="banna-box">
			<img src="../assets/image/banna-aex.png" />
			<div class="banna-title">
				<p>CONTACT US</p>
				<span></span>
				<h4>联系我们</h4>
			</div>
		</div>
		<div class="content">
			<div class="contact-box">
				<div class="container">
					<div class="datails-title">
						<h5>CONTACT US</h5>
						<h4>联系我们</h4>
					</div>
					<div class="contact">
						<div class="contact-top1">
							<div class="contact-tel">
								<img src="../assets/image/tel.png" />
								<p>联系电话</p>
								<h4>400-676-5955</h4>
							</div>
							<div class="contact-tel contact-map">
								<img src="../assets/image/map.png" />
								<p>公司地址</p>
								<h4>云南省昆明市西山区环城西路565号</h4>
							</div>
						</div>
						<div id="map-box">

						</div>
					</div>
					<div class="datails-title">
						<h5>ONLINE MESSAGE</h5>
						<h4>在线留言</h4>
					</div>
					<div class="message-box">
						<div class="message-list">
							<div class="message">
								<el-input v-model="name" placeholder="姓名" maxlength="6"></el-input>
								<i class="iconfont icon-xinghao"></i>
							</div>
							<div class="message">
								<el-input v-model="phone" placeholder="电话" maxlength="11"></el-input>
								<i class="iconfont icon-xinghao"></i>
							</div>
							<div class="message">
								<el-input v-model="mailbox" placeholder="邮箱" maxlength="18"></el-input>
								<i class="iconfont icon-xinghao"></i>
							</div>
						</div>
						<div class="message">
							<el-input
							  type="textarea"
							  :rows="2"
							  placeholder="请输入内容"
							  v-model="textarea">
							</el-input>
							<i class="iconfont icon-xinghao"></i>
						</div>
						<div class="message-shu">
							<el-input v-model="codeNumber" placeholder="验证码" maxlength="6"></el-input>
							<div class="code-box">
								<h4>{{code}}</h4>
							</div>
							<div class="no-see" @click="getCodeNew">
								<span>看不清，换一张</span>
							</div>
						</div>
						
						<div class="message-shu">
							<button type="submit" class="submit1" @click="getSubmit">提交</button>
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '../components/header.vue';
	import Footer from '../components/footer.vue';
	import AMapLoader from '@amap/amap-jsapi-loader';
	export default {
		components: {
			Header,
			Footer,
		},
		data() {
			return {
				active: '0',
				name:'',
				phone:'',
				mailbox:'',
				textarea:'',
				code:'',
				codeNumber:'',
			}
		},
		created() {
			this.initMap();
			this.getCode();
		},
		methods: {
			
			initMap() {
				AMapLoader.load({
					key: "88759be48fe1876a896e0194ad3b348a", // 申请好的Web端开发者Key，首次调用 load 时必填
					version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
					plugins: [''], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
				}).then((AMap) => {
					this.map = new AMap.Map("map-box", { //设置地图容器id
						viewMode: "3D", //是否为3D地图模式
						zoom: 19, //初始化地图级别
						buttonOffset: new AMap.Pixel(10, 20),
						center: [102.698294, 25.023823], //初始化地图中心点位置
					});
					var marker = new AMap.Marker({
						position: new AMap.LngLat(102.698294,
						25.023823), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
						content: `
					                                    <div class="my_marker">
					                                          <div class="showLog">
					                                               云南省昆明市西山区环城西路565号 
					                                          </div>
					                                          <div class="marker">
					                                                <div class="title">
					                                                     <i class="iconfont icon-dingwei1"></i>
					                                                </div>
					                                          </div>
					                                         
					                                          
					                                    </div>`,

						// icon: 'https://s1.ax1x.com/2022/05/27/XeLFGn.png', // 添加 Icon 图标 URL
						offset: new AMap.Pixel(-15, -20),

					});
					this.map.add([marker]);
				}).catch(e => {
					console.log(e);
				})
			},
			getCode(){
				this.$axios.get('/applyinfo.Applyinfo/getCode', {
					
				}).then(res => {
					this.code = res.data.str;
				}).catch(error => {
					console.log(error, '请求失败');
				})
			},
			getCodeNew(){
				this.getCode();
			},
			getSubmit(){
				let self = this;
				var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
				var mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
				if(self.name == ''){
					this.$message('姓名不能为空');
					return false
				}else if(self.phone == ''){
					this.$message('手机号码不能为空');
					return false
				}else if(!phonereg.test(self.phone)){
					this.$message('手机号码格式不正确');
					return false
				}else if(self.mailbox == ''){
					this.$message('邮箱不能为空');
					return false
				}else if(!mailReg.test(self.mailbox)){
					this.$message('邮箱格式不正确');
					return false
				}else if(self.textarea == ''){
					this.$message('留言不能为空');
					return false
				}else if(self.codeNumber == ''){
					this.$message('验证码不能为空');
					return false
				}else if(self.codeNumber != self.code){
					this.$message('验证码填写错误');
					return false
				}
				this.$axios.post('/applyinfo.Applyinfo/note', {
					user_name:self.name,
					mobile:self.phone,
					content:self.textarea,
					email:self.mailbox,
				}).then(res => {
					if(res.code == 1){
						this.$message({
							message: res.msg,
							type: 'success'
						});
						self.name = '';
						self.phone = '';
						self.textarea = '';
						self.mailbox = '';
						self.codeNumber = '';
					}else{
						this.$message('提交留言失败');
					}
				}).catch(error => {
					console.log(error, '请求失败');
				})
			},
			getNew() {
				this.$axios.get('/article.Article/newList', {
					per_page: this.pageSize,
					current_page: this.page,
				}).then(res => {
					this.tabLists = res.data.category_list;
					// console.log(this.tabLists);
				}).catch(error => {
					console.log(error, '请求失败');
				})
			},
			handleClick1(tab, event) {
				this.menuChildrens = tab.label;
				this.active = tab.index;
				this.newId = this.tabLists[tab.index].category_id
				console.log(this.newId);
			},
			getNewDet() {
				this.$router.push({
					path: "/new-details",
				});
			}
		}
	}
</script>

<style>
	.datails-title {
		/* margin-top: 160px; */
		margin-bottom: 40px;
		padding-top: 60px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.datails-title h5 {
		font-size: 48px;
		color: #303133;
		opacity: 0.1;
		margin: 0;
	}

	.datails-title h4 {
		font-size: 30px;
		color: #303133;
		margin: 0;
		margin-top: -10px;
	}

	.contact-top1 {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 30px 0;
	}

	.contact-tel {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 50px;
	}

	.contact-tel img {
		width: 46px;
		height: 36px;

	}

	.contact-tel p {
		margin: 0 10px;
	}

	.contact-tel h4 {
		font-size: 18px;
		color: #007AFF;
	}

	.contact-map h4 {
		font-size: 16px !important;
		color: #303133 !important;
	}

	#map-box {
		width: 100%;
		height: 540px;
	}
	.amap-marker{
		width: 240px !important;
		top: 230px !important;
	}
	.showLog{
		background-color: #fff;
		padding: 5px;
		position: relative;
		left: -120px;
		top: -5px;
	}
	.title i{
		font-size: 30px !important;
		color: #1296db !important;
	}
	.message-list{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.message{
		padding: 0 10px;
		background-color: #f5f5f5;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 4px;
	}
	.message-list .message{
		width: calc(33.33% - 40px);
		padding: 0 10px;
		background-color: #f5f5f5;
		display: flex;
		margin-bottom: 30px;
		min-height: 50px;
		align-items: center;
		justify-content: flex-start;
	}
	.message-list .message .el-input input{
		background-color: #f5f5f5 !important;
		border: none;
	}
	.message i {
		font-size: 12px;
		margin-right: 10px;
		color: #DF5000;
	}
	.message .el-textarea textarea{
		background-color: #f5f5f5 !important;
		border: none;
		height: 200px;
		margin: 10px 0;
	}
	.message-shu{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 30px;
	}
	.message-shu .el-input{
		width: 200px;
		padding: 10px 0;
	}
	.message-shu .el-input input{
		background-color: #f5f5f5 !important;
		border: none;
	}
	.message-shu button{
		width: 160px;
		height: 40px;
		border: none;
		border-radius: 4px;
		margin-bottom: 40px;
		cursor: pointer;
		font-size: 16px;
		letter-spacing: 2px;
	}
</style>
