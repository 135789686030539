import Vue from 'vue'
import Router from 'vue-router'

import home from '../pages/home.vue'
import Intr from '../pages/intr.vue'
import New from '../pages/new.vue' 
import NewDet from '../pages/new-details.vue'
import Details from '../pages/details.vue'
import Business from '../pages/business.vue'
import Recruitment from '../pages/recruitment.vue'
import RecruitmentDet from '../pages/recruitment-details.vue' 
import RecruitmentInfor from '../pages/recruitment-infor.vue'
import Contact from '../pages/contact.vue'
import axios from 'axios'
// 2. 把 VueRouter 安装为 Vue 项目的插件
// Vue.use() 函数的作用，就是来安装插件的
Vue.use(Router)
axios.defaults.withCredentials = true
Vue.prototype.$axios = axios;


export default new Router({
	routes: [{
		path: '/',
		redirect: '/home' //默认显示

	},{
		path: '/home',
		component: home
	},{
		path: '/intr',
		name: 'Intr',
		component: Intr
	},{
		path: '/new',
		name: 'New',
		component: RecruitmentDet
	},{
		path: '/new-details',
		name: 'NewDet',
		component: NewDet  
	},{
		path: '/details',
		name: 'Details',
		component: Details  
	},{
		path: '/Business',
		name: 'Business',
		component: Business
	},{
		path: '/Recruitment',
		name: 'Recruitment',
		component: Recruitment
	},{
		path: '/RecruitmentDet',
		name: 'RecruitmentDet',
		component: RecruitmentDet 
	},{
		path: '/RecruitmentInfor',
		name: 'RecruitmentInfor',
		component: RecruitmentInfor
	},{
		path: '/Contact',
		name: 'Contact',
		component: Contact
	}]
})
