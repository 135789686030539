<template>
	<div>
		<Header></Header>
		<div class="banna-box">
			<img src="../assets/image/banna-aex.png" />
			<div class="banna-title">
				<p>RECRUIT</p>
				<span></span>
				<h4>人才招聘</h4>
			</div>
		</div>
		<div class="content">
			<div class="recruit-box">
				<div class="container">
					<div class="datails-title">
						<h5>RECRUIT</h5>
						<h4>人才招聘</h4>
					</div>
					<div class="recruit-list">
						<div class="recruit-shu">
							<p>真实姓名：</p>
							<el-input v-model="name" placeholder="" maxlength="6"></el-input>
							<i class="iconfont icon-xinghao"></i>
						</div>
						<div class="recruit-shu">
							<p>联系电话：</p>
							<el-input v-model="phone" placeholder="" maxlength="11"></el-input>
							<i class="iconfont icon-xinghao"></i>
						</div>
						<div class="recruit-shu">
							<p>性别：</p>
							<div class="radio-list">
								<el-radio v-model="radio" label="男">男</el-radio>
								<el-radio v-model="radio" label="女">女</el-radio>
							</div>
							<i class="iconfont icon-xinghao"></i>
						</div>
						<div class="recruit-shu" maxlength="5">
							<p>工作年限：</p>
							<el-input v-model="workYear" placeholder=""></el-input>
							<i class="iconfont icon-xinghao"></i>
						</div>
						<div class="recruit-shu">
							<p>应聘职位：</p>
							<el-input v-model="workName" placeholder=""></el-input>
							<i class="iconfont icon-xinghao"></i>
						</div>
						<div class="recruit-shu">
							<p>出生日期：</p>
							<el-input v-model="year" placeholder=""></el-input>
							<i class="iconfont icon-xinghao"></i>
						</div>
						<div class="recruit-shu">
							<p>最高学历：</p>
							<el-select v-model="school" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
							<i class="iconfont icon-xinghao"></i>
						</div>
						<div class="recruit-shu">
							<p>年龄：</p>
							<el-input v-model="age" placeholder="" maxlength="6"></el-input>
							<i class="iconfont icon-xinghao"></i>
						</div>
						<div class="recruit-shu">
							<p>毕业院校：</p>
							<el-input v-model="schoolName" placeholder=""></el-input>
							<i class="iconfont icon-xinghao"></i>
						</div>
						<div class="recruit-shu">
							<p>工作经历：</p>
							<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea">
							</el-input>
							<i class="iconfont icon-xinghao"></i>
						</div>
						<div class="recruit-shu mailbox">
							<p>邮箱：</p>
							<el-input v-model="mailbox" placeholder="" maxlength="18"></el-input>
							<i class="iconfont icon-xinghao"></i>
						</div>
						<div class="recruit-shu code-number">
							<p>验证码：</p>
							<el-input v-model="codeNumber" placeholder="" maxlength="6"></el-input>
							<div class="code-box">
								<h4>{{code}}</h4>
							</div>
							<div class="no-see" @click="getCodeNew">
								<span>看不清，换一张</span>
							</div>
						</div>

						<div class="recruit-shu button-list">
							<button type="submit" class="submit1" @click="getSubmit">提交</button>
							<button type="button" @click="getReset">重置</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '../components/header.vue';
	import pageTab from '../components/pageTab.vue';
	import Footer from '../components/footer.vue';
	export default {
		components: {
			Header,
			Footer,
			pageTab
		},
		data() {
			return {
				active: '0',
				name: '',
				radio: '',
				textarea: '',
				code: '',
				phone: '',
				school: '',
				workYear: '',
				workName: '',
				year: '',
				schoolName: '',
				mailbox: '',
				codeNumber: '',
				age: '',
				options: [{
						label: '本科',
						value: '本科',
					},
					{
						label: '大专',
						value: '大专',
					},
					{
						label: '高中',
						value: '高中',
					},
					{
						label: '其他',
						value: '其他',
					}
				]
			}
		},
		created() {
			this.getCode();
		},
		methods: {
			getCode() {
				this.$axios.get('/applyinfo.Applyinfo/getCode', {

				}).then(res => {
					this.code = res.data.str;
				}).catch(error => {
					console.log(error, '请求失败');
				})
			},
			getSubmit() {
				let self = this;
				var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
				var mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
				if (self.name == '') {
					this.$message('姓名不能为空');
					return false
				} else if (self.phone == '') {
					this.$message('手机号码不能为空');
					return false
				} else if (!phonereg.test(self.phone)) {
					this.$message('手机号码格式不正确');
					return false
				} else if (self.radio == '') {
					this.$message('请选择性别');
					return false
				} else if (self.workYear == '') {
					this.$message('工作年限不能为空');
					return false
				} else if (self.workName == '') {
					this.$message('应聘职位不能为空');
					return false
				} else if (self.year == '') {
					this.$message('出生日期不能为空');
					return false
				} else if (self.school == '') {
					this.$message('最高学历不能为空');
					return false
				} else if (self.age == '') {
					this.$message('年龄不能为空');
					return false
				} else if (self.schoolName == '') {
					this.$message('毕业院校不能为空');
					return false
				} else if (self.mailbox == '') {
					this.$message('邮箱不能为空');
					return false
				} else if (!mailReg.test(self.mailbox)) {
					this.$message('请输入正确的邮箱格式');
					return false
				} else if (self.textarea == '') {
					this.$message('工作经历不能为空');
					return false
				} else if (self.codeNumber == '') {
					this.$message('验证码不能为空');
					return false
				} else if (self.codeNumber != self.code) {
					this.$message('验证码填写错误');
					return false
				}
				this.$axios.post('/applyinfo.Applyinfo/applyAdd', {
					user_name: self.name,
					mobile: self.phone,
					sex: self.radio,
					worke_years: self.workYear,
					position: self.workName,
					birth_date: self.year,
					education: self.school,
					school: self.schoolName,
					work_content: self.textarea,
					email: self.mailbox,
					age: self.age,
				}).then(res => {
					if (res.code == 1) {
						// this.$message();
						this.$message({
							message: res.msg,
							type: 'success'
						});
					} else {
						this.$message('提交失败');
					}
				}).catch(error => {
					console.log(error, '请求失败');
				})
			},
			getReset() {
				let self = this;
				self.name = '';
				self.phone = '';
				self.radio = '';
				self.workYear = '';
				self.workName = '';
				self.year = '';
				self.school = '';
				self.schoolName = '';
				self.mailbox = '';
				self.textarea = '';
				self.codeNumber = '';
				self.age = '';
			},
			getCodeNew() {
				this.getCode();
			},
			handleClick1(tab, event) {
				this.menuChildrens = tab.label;
				this.active = tab.index;
				console.log(this.active);
			},
			getNewDet() {
				this.$router.push({
					path: "/new-details",
				});
			}
		}
	}
</script>

<style>
	.datails-title {
		/* margin-top: 160px; */
		margin-bottom: 40px;
		padding-top: 60px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.datails-title h5 {
		font-size: 48px;
		color: #303133;
		opacity: 0.1;
		margin: 0;
	}

	.datails-title h4 {
		font-size: 30px;
		color: #303133;
		margin: 0;
		margin-top: -10px;
	}

	.content {
		background-color: #fff;
	}

	.recruit-list {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.recruit-box {
		background-color: #fff;
	}

	.recruit-shu {
		width: 48%;
		margin: 0 1%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: 50px;
		padding: 5px 0;
		background-color: #f5f5f5;
		border-radius: 2px;
		margin-bottom: 30px;
	}

	.recruit-shu .el-input {
		width: calc(100% - 130px);
	}

	.recruit-shu .el-input__inner {
		border: none !important;
	}

	.recruit-shu i {
		font-size: 12px;
		margin-right: 10px;
		color: #DF5000;
	}

	.recruit-shu p {
		display: inline-block;
		width: 90px;
		margin-left: 10px;
		text-align: left;
	}

	.page {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
		padding-bottom: 60px;
		margin-bottom: 0 !important;
	}

	.radio-list {
		text-align: left;
		width: calc(100% - 130px);
	}

	.recruit-shu .el-select {
		width: calc(100% - 130px);
	}

	.recruit-shu .el-select .el-input {
		width: 100%;
	}

	.recruit-shu .el-select .el-input input {
		background-color: #f5f5f5;
		border: none;
	}

	.recruit-shu .el-textarea {
		width: calc(100% - 130px);
		height: 210px;
	}

	.recruit-shu .el-textarea textarea {
		height: 210px;
		background-color: #fff;
		border: none;
	}

	.code-number {
		justify-content: flex-start;
		position: relative;
		top: -70px;
		left: -50%;
	}

	.mailbox {
		justify-content: flex-start;
		position: relative;
		top: -160px;
	}

	.code-number .el-input {
		width: 100px !important;
	}

	.code-box {
		width: 111px;
		height: 40px;
		border-radius: 4px 4px 4px 4px;
		background: rgba(237, 237, 237, 1);
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 15px;
		letter-spacing: 2px;
	}

	.no-see span {
		font-size: 14px;
		cursor: pointer;
	}

	.button-list {
		justify-content: flex-start;
		background-color: #fff;
		position: relative;
		top: -60px;
		/* left: -50%; */
	}

	.button-list button {
		width: 160px;
		height: 40px;
		border-radius: 4px;
		margin: 0 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		cursor: pointer;
	}

	.submit1 {
		background-color: #007AFF;
		color: #fff;
	}
	.recruit-shu .el-select .el-input input{
		background-color: #fff !important;
	}
</style>
