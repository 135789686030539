<template>
	<div class="pageTab">
		<el-tabs v-model="labelName" @tab-click="handleClick">
			<el-tab-pane :label="item.name" v-for="(item,index) in tabList" >{{item.name}}</el-tab-pane>
		</el-tabs>
		<div class="map">
			<i class="iconfont icon-dingwei"></i>
			<i class="iconfont icon-more"></i>
			<p>{{menu}}</p>
			<i class="iconfont icon-more"></i>
			<p>{{menuChildren}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'pageTab',
		props: {
		  tabList:{
			  type:Array,
			  default:()=>[]
		  },
		  menu:{
			  type:String,
			  default:''
		  },
		  menuChildren:{
			  type:String,
			  default:''
		  },
		  activeName:{
			 type:String,
			 default:''
		  },
		},
		data() {
			return {
				labelName: '',
				
			}
		},
		methods: {
			handleClick(tab, event) {
				this.$emit('handleClick',tab, event)
			}
		},
	}
</script>

<style>
	.pageTab {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 1600px;
		position: relative;
		overflow: hidden;
		height: 60px;
	}
	.el-tabs{
		position: absolute;
		width: 1600px;
		left: 0;
	}
	.map {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: absolute;
		    right: 0;
	}
	.map i{
		font-size: 16px;
		margin: 0 5px;
	}
	.map p{
		font-size: 14px;
	}
	.map p:last-child{
		color: #409EFF !important;
	}
	.map i:first-child{
		font-size: 20px;
	}
	.el-tabs__content {
		display: none;
	}

	.el-tabs__header {
		margin-bottom: 0 !important;
	}

	.el-tabs__bar {
		background-color: #fff !important;
	}
	.el-tabs__item{
		height: 60px !important;
		line-height: 60px !important;
	}
</style>
