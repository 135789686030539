<template>
	<div>
		<full-page :options="options" ref="page">
			<div class="section bannaList">
				<Header></Header>
				<SwiperBanna  :bannaList='bannaList'></SwiperBanna>
			</div>
			<div class="section">
				<div class="Intr-boxs">
					<div class="Intr-left Intr-box">
						<img src="../assets/image/intr.png">
						<div class="Intr-zhe">
							<div class="Intr-zhe-left">
								<h4>2006</h4>
								<p>公司成立年份(年）</p>
								<h4>1000+</h4>
								<p>公司现有职工(人）</p>
								<h4>781933</h4>
								<p>公司上市代码</p>
							</div>
							<div class="Intr-zhe-right">
								<i class="icon iconfont icon-play"></i>
							</div>
						</div>
					</div>
					<div class="Intr-right Intr-box">
						<div class="headers">
							<h5>Group profile</h5>
							<h4>{{companyData.article_title}}</h4>
						</div>
						<div class="Intr-infor">
							<!-- <h4>云南省爱尔信集团有限公司</h4> -->
							<p v-html="companyData.article_content"></p>
							<el-button type="primary" class="see-more"  @click="goPath('/Intr')">查看更多
								<i class="icon iconfont icon-more"></i>
							</el-button>
						</div>
						<div class="Intr-menu-box">
							<div class="Intr-menu" v-for="(ite1,index1) in categoryList">
								<i class="iconfont icon-qiye"></i>
								<p>{{ite1.name}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="new-boxs">
					<div class="news-header">
						<div class="headers">
							<h5>Group profile</h5>
							<h4>集团新闻</h4>
						</div>
						<div class="news-tabs">
							<div :class="newCur == index ? 'active1' :''" v-for="(item,index) in newList"
								@click="newTad(index,item)">
								<p>{{item.name}}</p>
							</div>
							<div class="mores" >
								<p 	@click="goPath('/RecruitmentDet')">查看更多</p>
								<i class="iconfont icon-more"></i>
							</div>
						</div>
					</div>
					<div class="newList-box">
						<div class="swiper-container1">
							<div class="swiper-wrapper">
								<div class="swiper-slide" v-for="iten in newInforList" 	@click="goPath('/new-details',iten.article_id)">
									<a href="#" >
										<img v-bind:src="iten.image.file_path" />
										<h5>{{iten.create_time}}</h5>
										<h4>{{iten.article_title}}</h4>
										<p>{{iten.dec}}</p>
									</a>
								</div>
							</div>
							<!-- 如果需要导航按钮 -->
							<div class="swiper-button-prev"></div>
							<div class="swiper-button-next"></div>
							<!-- 如果需要滚动条 -->
							<div class="swiper-scrollbar"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="business-boxs">
					<div class="business-header">
						<div class="headers">
							<h5>Group profile</h5>
							<h4>业务板块</h4>
						</div>
						<!-- <div class="mores">
							<p>查看更多</p>
							<i class="iconfont icon-more"></i>
						</div> -->
					</div>
					<div class="business-list">
						<a href="#" v-for="(ite,index) in businessList" @click="goPath('/Business')">
							<img class="business-img" v-bind:src="ite.img">
							<div class="business-infor">
								<img src="../assets/image/business-icon.png">
								<p>{{ite.businessInfor}}</p>
								<h4>{{ite.title}}</h4>
								<i class="iconfont icon-jia"></i>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="section fp-auto-height">
				<Footer></Footer>
			</div>
		</full-page>
	</div>
</template>
<script>
	import Header from '../components/header.vue';
	import SwiperBanna from '../components/swiperbanna.vue';
	import Footer from '../components/footer.vue';
	export default {
		components: {
			Header,
			SwiperBanna,
			Footer
		},
		data() {
			return {
				options: {
					// key
					licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
					// 是否显示导航，默认为false
					navigation: true,
					// 是否显示横向幻灯片的导航
					slidesNavigation: true,
					// 横向幻灯片导航的位置，可以为top或者bottom
					slidesNavPosition: 'bottom',
					// 横向slide幻灯片是否循环滚动
					// time:'',

				},
				bannaHeight: document.body.clientHeight - 82,
				bannaList: [],
				categoryList:[],
				imgUrl:'https://api.aierxin.com',
				newList: [{
						head: '公司新闻'
					},
					{
						head: '行业新闻'
					}
				],
				companyData:[],
				newCur: 0,
				newInforList: [],
				businessList:[
					{
						img:'https://api.aierxin.com/image/pc_image/edu.png',
						imgIcon:'https://api.aierxin.com/image/pc_image/edu.png',
						businessInfor:'Financial sector',
						title:'大教育产业综合体',
					},
					{
						img:"https://api.aierxin.com/image/pc_image/edu.png",
						imgIcon:"https://api.aierxin.com/image/pc_image/edu.png",
						businessInfor:'Financial sector',
						title:'大财税产业综合体',
					}
					
				],
				
			}
		},
		created() {
			this.getData();
			this.getBanna();
		},
		mounted() {
			var swiper1 = new Swiper('.swiper-container1', {
				pagination: {
					el: ".swiper-pagination",
					type: "progressbar",
				},
				scrollbar:'.swiper-scrollbar',
				nextButton: '.swiper-button-next', //前进按钮
				prevButton: '.swiper-button-prev', //后退按钮
				loop: false, //循环
				draggable:true,
				hide: false,
				paginationClickable: true, //分页器点击
				spaceBetween: 30, // swiper-slide 间的距离为30
				autoplay: false, //时长
				slidesPerView: 3,
			});
		},
		methods: {
			getData(){
				this.$axios.get('/index.index/index', {
					
				}).then(res => {
					this.categoryList = res.data.category_list;
					this.newList = res.data.new_category_list;
					this.companyData =  res.data.company;
					this.newInforList = res.data.article_list;
          console.log(this.newInforList)
					// console.log(this.categoryList);
					// this.content = res.data.datails.article_content;
				}).catch(error => {
					console.log(error, '请求失败');
				})
			},
			getBanna(){
				this.$axios.get('/banner.Banner/list', {
					
				}).then(res => {
					
					// for(var i =0; i < res.data.list.length; i++ ){
					// 	res.data.list[i].banner_image = res.data.list[i].banner_image;
					// }
					this.bannaList = res.data.list;
					console.log(this.bannaList);
					
				}).catch(error => {
					console.log(error, '请求失败');
				})
			},
			newTad(index) {
				this.newCur = index;
			},
			goPath(url,id = 0 ){
				//存入缓存
				localStorage.setItem('det',id);
				this.$router.push({
				  path:url,
				});
			},
			business(index){
					console.log(index);
				}
			},
			Path(index){
				console.log(index);
				// this.$router.push({
				//   path:url,
				// });
			}
		
	}
</script>
<style>
	.bannaList .swiper-container {
		width: 100%;
		height: calc(100% - 82px);
		margin-top: 0 !important;
	}
	
	.bannaList .swiper-container img {
		width: 100%;
		height: 100%;
	}

	.bannaList .swiper-slide {
		width: 100% !important;
		overflow: hidden;
	}

	.bannaList .swiper-button-prev,
	.bannaList .swiper-button-next {
		top: 95% !important;
		width: 36px !important;
		height: 32px !important;
		left: auto !important;
		border: 1px solid #3A8EE6;
		padding: 4px 3px;
		border-radius: 50%;
		background-size: 50% 50% !important;
		/* background:url(../assets/image/next.png) no-repeat !important;
		background-size: 100% 100% !important; */
	}

	.swiper-button-prev {}

	.bannaList .swiper-button-prev {
		right: 220px !important;
	}

	.bannaList .swiper-button-next {
		right: 100px !important;
	}

	.bannaList .swiper-button-disabled {
		/* background-color: transparent !important; */
		opacity: 0.8 !important;
		/* background:url(../assets/image/prev.png) no-repeat !important;
		background-size: 100% 100% !important; */
		/* transform: rotate(180deg); */
	}

	.Intr-boxs {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		overflow: hidden;
	}

	.Intr-box {
		width: 50%;
		overflow: hidden;
		position: relative;
	}

	.Intr-left img {
		width: 100%;
	}

	.Intr-zhe {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.Intr-zhe-left {
		width: 50%;
		height: 100%;
		background: url(../assets/image/intr-zhe.png) no-repeat;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: #fff;
	}

	.Intr-zhe-left h4 {
		font-size: 40px !important;
	}

	.Intr-zhe-left p {
		font-size: 12px;
	}

	.Intr-zhe-right {
		width: 50%;
		height: 100%;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.Intr-zhe-right i {
		font-size: 38px;
		cursor: pointer;
	}

	.Intr-right {
		width: 50%;
		height: 100%;
		background: rgba(241, 248, 255, 1);
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
	}

	.headers {
		/* margin-top: 160px; */
		margin-left: 80px;
		margin-bottom: 100px;
		width: 720px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
	}

	.Intr-infor {
		margin-left: 80px;
		margin-bottom: 60px;
		width: 720px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
	}

	.Intr-infor p {
		margin: 10px 0;
		font-size: 16px;
		color: #303133;
		line-height: 24px;
		text-align: left;
	}

	.headers h5 {
		font-size: 48px;
		color: #303133;
		opacity: 0.1;
		margin: 0;
	}

	.headers h4 {
		font-size: 30px;
		color: #303133;
		margin: 0;
		margin-top: -10px;
	}

	.Intr-infor h4 {
		font-size: 18px;
		color: #303133;
	}

	.see-more {
		width: 120px !important;
		height: 46px !important;
		border-radius: 5px 5px 5px 5px;
		background: rgba(0, 58, 151, 1) !important;
		color: #fff;
		margin-top: 30px !important;
	}

	.Intr-menu-box {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		align-items: center;
		width: 720px;
		margin-left: 80px;
		margin-top: 40px;
	}

	.Intr-menu-box .Intr-menu {
		color: #303133;
		/* width: 24.9%;
		text-align: left; */
		margin-right: calc(20% - 56px);
		cursor: pointer;
		position: relative;

	}

	.Intr-menu-box .Intr-menu i {
		font-size: 32px;
		margin-left: 5px;
	}

	.Intr-menu-box .Intr-menu p {
		font-size: 14px;
		margin: 8px 0;
	}

	.Intr-menu-box .Intr-menu:hover {
		color: rgba(0, 58, 151, 1) !important;
	}

	.Intr-menu-box .Intr-menu:not(:last-child)::after {
		content: '';
		width: 1px;
		height: 40px;
		background-color: #303133;
		position: absolute;
		z-index: 4;
		right: 0;
		left: 200%;
		top: 10%;
		bottom: 10%;
	}

	.new-boxs {
		width: 1600px;
		height: 100%;
		margin: 0 auto;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		overflow: hidden;
	}

	.news-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.news-header .headers {
		margin-left: 0;
	}

	.news-tabs {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.news-tabs div {
		width: 120px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px 5px 5px 5px;
		background: rgba(245, 245, 245, 1);
		color: #303133;
		margin-left: 10px;
		cursor: pointer;
	}
	.mores{
		width: 120px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px 5px 5px 5px;
		background: rgba(245, 245, 245, 1);
		color: #303133;
		margin-left: 10px;
		cursor: pointer;
	}
	.active1 {
		background: rgba(0, 58, 151, 1) !important;
		color: #fff !important;
	}

	.newList-box {
		position: relative;
		width: 100%;
	}
	.swiper-scrollbar{
		opacity: 1 !important;
	}
	.newList-box .swiper-slide{
		width: calc(33.33% - 20px) !important;
		a{
			margin: 0 10px;
		}
	}
	.newList-box .swiper-slide img{
		/* width: 100%; */
		height:294px !important;
	}
	
	.newList-box .swiper-slide a{
		text-align: left;
		color: #303133;
		display: inline-block;
	}
	.newList-box .swiper-slide a p{
		font-size: 14px;
		color: #666666;
		margin: 20px 0 12px 0;
	}
	.newList-box .swiper-slide a h4{
		font-size: 20px;
		color: #303133;
		margin-bottom: 20px;
	}
	.newList-box .swiper-slide a p{
		font-size: 14px;
		color: #303133;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		line-height: 24px;
		/* margin-bottom: 20px; */
	}
	.newList-box  .swiper-scrollbar{
		width: 80% !important;
		margin: 0 10% !important;
		bottom:-40px !important
	}
	.newList-box .swiper-button-prev, .newList-box .swiper-button-next{
		top: 110.5% !important;
		    width: 27px !important;
		    height: 20px !important;
	}
	.newList-box .swiper-button-prev{
		left: 88px !important;
	}
	.newList-box .swiper-button-next{
		right: 78px !important;
	}
	.swiper-scrollbar-drag{
		background: #003A97 !important;
	}
	.business-boxs{
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.business-list{
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.business-header{
		width: 1600px;
		position: absolute;
		top: 100px;
		left: calc(50% - 800px);
		display: flex;
		align-items: center;
		justify-content: space-between;
		z-index: 5;
	}
	.business-header .headers{
		margin-left: 0;
	}
	.business-list a{
		width: 25%;
		height: 100%;
		position: relative;
		overflow: hidden;
		display: inline-block;
		color: #fff;
		text-align: center;
		cursor: pointer;
		z-index: 2;
	}
	.business-list a .business-infor{
		position: absolute;
		top: 0;
		width: 100%;
		text-align: center;
		height: 100%;
		z-index: 3;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.business-list a .business-infor img{
		width: 40px;
		height: 42px;
		margin-bottom: 20px;
	}
	.business-infor p{
		font-size: 24px;
		color: #fff;
		opacity: 0.2;
		margin-bottom: 24px;
	}
	.business-infor h4{
		font-size: 24px;
		color: #fff;
		margin-bottom: 65px;
	}
	.business-infor  i{
		font-size: 24px;
		padding: 10px;
		border: 1px solid #fff;
		border-radius: 50% 50%;
		overflow: hidden;
	}
	.business-list .business-infor:hover{
		background: url(../assets/image/intr-zhe.png) no-repeat;
		background-size: 100% 100%;
	}
	.business-img{
		width: 100%;
	}
	.fp-auto-height .fp-tableCell{
		height: auto !important; 
	}
</style>
