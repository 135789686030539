<template>
	<div>
		<Header></Header>
		<div class="banna-box">
			<img src="../assets/image/banna-aex.png" />
			<div class="banna-title">
				<p>Group </p>
				<span></span>
				<h4>集团简介</h4>
			</div>
		</div>
		<div class="content">
			<div class="container">
				<pageTab @handleClick='handleClick1' :tabList='tabLists' :menu='menus' :menuChildren='menuChildrens'
					:activeName='active'>
				</pageTab>
			</div>
			<div class="details-box" v-show="active == 0">
				<div class="container">
					<div class="datails-title">
						<h5>Group profile</h5>
						<h4>集团简介</h4>
					</div>
					<div class="details-infor details-infor-box">
						<div class="img-box">
							<img src="../assets/image/intr.png" />
						</div>
						<p v-html="content"></p>
					</div>
				</div>
			</div>
			<div class="details-box" v-show="active == 1">
				<div class="container">
					<div class="datails-title">
						<h5>Group profile</h5>
						<h4>企业文化</h4>
					</div>
					<div class="details-infor">
						<img v-bind:src="image_path"/>
					</div>
					<!-- <div class="details-infor">
						<div class="culture-list">
							<div class="culture">
								<div class="culture-header">
									<p>Mission</p>
									<h5>爱尔信<h4>使命</h4>
									</h5>
								</div>
								<p>修己安人，创造员工和客户物质与精神的共同幸福，为社会进步发展作出贡献。</p>
							</div>
							<div class="culture">
								<div class="culture-header">
									<p>Vision</p>
									<h5>爱尔信<h4>愿景</h4>
									</h5>
								</div>
								<p>致力于构建财经产业链智能数字化生态平台和智慧幸福型企业组织。
								</p>
							</div>
							<div class="culture">
								<div class="culture-header">
									<p>Core values</p>
									<h5>爱尔信<h4>核心价值观</h4>
									</h5>
								</div>
								<p>仁爱、诚信、敬业、客户至上、团队合作、拥抱变化。</p>
							</div>
						</div>
						<div class="culture-bet">
							<div class="culture-bet-left">
								<div class="culture">
									<div class="culture-header">
										<p>Six Source Han Serif SC</p>
										<h5><h4>六项精进</h4></h5>
									</div>
									<p>付出不亚于任何人的努力；要谦虚、不要骄傲；</p>
									<p>要每天反省；活着，就要感谢；</p>
									<p>付出不亚于任何人的努力；</p>
									<p>集善行，思利他；不要有感性的烦恼。</p>
								</div>
								<div class="culture">
									<div class="culture-header">
										<p>Words of gratitude</p>
										<h5>
											<h4>感恩词</h4>
										</h5>
									</div>
									<p>感恩天地阳光雨露，感恩国家恩惠护佑；</p>
									<p>感恩父母养育之恩，感恩企业助我成长；</p>
									<p>感恩老师谆谆教诲，感恩大众辛勤付出。</p>
									<p>（感恩）</p>
								</div>
							</div>
							<div class="culture-bet-right">
								<div class="culture">
									<div class="culture-header">
										<p>Are you ready?</p>
										<h5>
											<h4>你准备好了吗?</h4>
										</h5>
									</div>
									<p>当我梦想伟大成功的时候，我有没有刻苦的准备？</p>
									<p>当我有野心做领袖的时候，我有没有服务于人的谦恭？</p>
									<p>我常常都想有所获得，但我有没有付出的情操？</p>
									<p>我都希望别人听到自己的说话，但我有没有耐性聆听别人？</p>
									<p>每一个人都希望自己快乐，但我对失落、悲伤的人有没有怜悯？</p>
									<p>每一个人都希望站在人前，但我是否知道什么时候甘为人后？</p>
									<p>我知道自己追求什么，但我知道自己需要什么吗？</p>
									<p>我常常希望改变别人，但我知道什么时候改变自己吗？</p>
									<p>每一个人都懂得批判别人，但不是每一个人都知道怎样自我反省！</p>
									<p>大家都看重面子，但我知道什么是面子吗？</p>
									<p>大家都希望拥有财富，但我知道财富的意义吗？</p>
									<p>各位伙伴，相信我们都有各种激情，但我知不知道什么是爱？</p>
								</div>
							</div>
						</div>
					</div> -->
				</div>
			</div>
			<div class="details-box" v-show="active == 2" style="background-color: #EBF3FF;">
				<div class="container">
					<div class="datails-title">
						<h5>History</h5>
						<h4>发展历程</h4>
					</div>
					<div class="History-box">
						<div class="History-title">
							<h4>2006-{{year}}</h4>
							<p>爱尔信集团成长历程</p>
						</div>
						<div class="swiper-container-History">
							<div class="swiper-wrapper" v-for="(item,index) in details">
								<div class="swiper-slide" >
									<div class="slide-box">
										<div class="xian-left">
											<span></span>
											<p></p>
										</div>
										<div class="History-details-box">
											<img :src="item.image.file_path" />
											<p v-html="item.article_content"> </p>
											
										</div>
									</div>
								</div>
								
							</div>
						</div>
						<div class="swiper-button-prev"></div>
						<div class="swiper-button-next"></div>
					</div>
				</div>
			</div>
			<div class="details-box" v-show="active == 3">
				<div class="container">
					<div class="datails-title">
						<h5>Organizational Structure </h5>
						<h4>组织架构</h4>
					</div>
					<div class="details-infor">
						<img v-bind:src="image_path"/>
					</div>
				</div>
			</div>
			<div class="details-box" v-show="active == 4">
				<div class="container">
					<div class="datails-title">
						<h5>Group profile</h5>
						<h4>荣誉资质</h4>
					</div>
					<div class="details-infor">
						<div class="honor-list" v-for="(item,index) in details">
							<div class="honor">
								<img :src="item.image.file_path" />
								<p>{{item.article_title}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '../components/header.vue';
	import pageTab from '../components/pageTab.vue';
	import SwiperBanna from '../components/swiperbanna.vue';
	import Footer from '../components/footer.vue';
	export default {
		components: {
			Header,
			Footer,
			pageTab,
			SwiperBanna
		},
		data() {
			return {
				active: '0',
				menus: '集团简介',
				menuChildrens: '集团简介',
				intrId:9,
				tabLists: [],
				content:'',
				image_path:'',
				culture_image_path:'',
				details:{},
				year:''
			}
		},
		created() {
			this.getIntr();
			this.year=this.currentYear();
		},
		mounted() {
			var swiper = new Swiper('.swiper-container-History', {
				// pagination: {
				// 	el: ".swiper-pagination",
				// 	type: "progressbar",
				// },
				// scrollbar:'.swiper-scrollbar',
				nextButton: '.swiper-button-next', //前进按钮
				prevButton: '.swiper-button-prev', //后退按钮
				loop: false, //循环
				draggable: true,
				hide: false,
				paginationClickable: false, //分页器点击
				spaceBetween: 30, // swiper-slide 间的距离为30
				autoplay: false, //时长
				slidesPerView: 3,

			});
		},
		methods: {
			getIntr(){
				this.$axios.get('/index.Index/companyInfo', {
					category_id: this.intrId,
				}).then(res => {
					this.tabLists = res.data.category_list;
					let data=res.data.datails;
					console.log(data);
					if(data.length>1){
						this.details=data;
					}else{
						this.content = res.data.datails.article_content;
						this.image_path = res.data.datails.image.file_path;
					}
					
				}).catch(error => {
					console.log(error, '请求失败');
				})
			},
			 currentYear() {
			      return new Date().getFullYear();
			    },
			handleClick1(tab, event) {
				this.menuChildrens = tab.label;
				this.active = tab.index;
				this.intrId = this.tabLists[tab.index].category_id;
				this.getIntr();
			}
		}
	}
</script>

<style>
	.content {
		background-color: #fff;
	}

	.banna-box {
		width: 100%;
		height: 460px;
		position: relative;
	}

	.banna-box img {
		width: 100%;
		height: 100%;
	}

	.banna-title {
		width: 1600px;
		margin: 0 auto;
		display: flex;
		height: 50px;
		position: absolute;
		top: 205px;
		left: calc(50% - 800px);
		align-items: center;
		justify-content: flex-start;
		color: #fff;
		font-family: 'Source Han Sans SC';
	}

	.banna-title span {
		width: 70px;
		height: 2px;
		background-color: #fff;
		margin: 0 40px;
		display: inline-block;
	}

	.banna-title p {
		font-size: 30px;
	}

	.banna-title h4 {
		font-size: 48px;
		letter-spacing: 2px;
	}

	.datails-title {
		/* margin-top: 160px; */
		margin-bottom: 40px;
		padding-top: 60px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.datails-title h5 {
		font-size: 48px;
		color: #303133;
		opacity: 0.1;
		margin: 0;
	}

	.datails-title h4 {
		font-size: 30px;
		color: #303133;
		margin: 0;
		margin-top: -10px;
	}

	.details-infor {
		text-align: left;
		font-size: 16px;
		line-height: 30px;
		margin-bottom: 40px;
		padding-bottom: 40px;
	}

	.details-infor-box {
		text-indent: 2em;
	}

	.img-box {
		width: 750px;
		height: 421px;
		margin-left: 0px;
		margin-bottom: 0px;
		float: right;
		clear: both
	}

	.details-infor img {
		width: 100%;
		height: 100%;
		/* display: inline-block; */
	}

	.culture-list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.culture {
		width: calc(33.3% - 20px);
		min-height: 290px;
		height: 100%;
	}

	.culture-header {
		height: 85px;
		padding-left: 30px;
		margin-top: 20px;
		width: 80%;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		margin-bottom: 10px;
	}

	.culture-header h5 {
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		font-size: 20px;

	}

	.culture-header h5 h4 {
		font-size: 30px;
		position: relative;
		top: -4px;
		margin: 0 4px;
	}

	.culture p {
		margin-left: 30px;
		font-size: 16px;
		line-height: 34px;
		width: 80%;
	}

	.culture-header p {
		font-size: 18px;
		margin: 0;
		position: relative;
		top: -2px;
	}

	.culture-list .culture:nth-child(1) {
		background-color: #F1F7FF;
	}

	.culture-list .culture:nth-child(1) p {
		color: #004AC1;
	}

	.culture-list .culture:nth-child(1) .culture-header {
		background: linear-gradient(90deg, rgba(0, 74, 193, 0.1) 0%, #F1F7FF 100%);
	}

	.culture-list .culture:nth-child(1) .culture-header p {
		color: #004AC1;
	}

	.culture-list .culture:nth-child(1) .culture-header h5 {
		color: #004AC1;
	}

	.culture-list .culture:nth-child(2) {
		background-color: #F1F7FF;
	}

	.culture-list .culture:nth-child(2) p {
		color: #283262;
	}

	.culture-list .culture:nth-child(2) .culture-header {
		background: linear-gradient(90deg, rgba(40, 50, 98, 0.1) 0%, #F3F5FE 100%);
	}

	.culture-list .culture:nth-child(2) .culture-header p {
		color: #283262;
	}

	.culture-list .culture:nth-child(2) .culture-header h5 {
		color: #283262;
	}

	.culture-list .culture:nth-child(3) {
		background-color: #FFF4F3;
	}

	.culture-list .culture:nth-child(3) p {
		color: #E21404;
	}

	.culture-list .culture:nth-child(3) .culture-header {
		background: linear-gradient(90deg, rgba(226, 20, 4, 0.1) 0%, #FFF4F3 100%);
	}

	.culture-list .culture:nth-child(3) .culture-header p {
		color: #E21404;
	}

	.culture-list .culture:nth-child(3) .culture-header h5 {
		color: #E21404;
	}

	.culture-bet {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	.culture-bet .culture {
		width: 100%;

	}

	.culture-bet-left {
		width: calc(50% - 15px);
	}

	.culture-bet-right {
		width: calc(50% - 15px);
		height: 630px;
	}

	.culture-bet .culture {
		padding-top: 20px !important;
		margin-top: 30px;
	}

	.culture-bet .culture-header {
		margin-top: 0;
	}

	.culture-bet-left .culture:nth-child(1) {
		background-color: #F1F7FF;
	}

	.culture-bet-left .culture:nth-child(1) p {
		color: #A6400A;
	}

	.culture-bet-left .culture:nth-child(1) .culture-header {
		background: linear-gradient(90deg, rgba(166, 64, 10, 0.1) 0%, #F1F7FF 100%);
	}

	.culture-bet-left .culture:nth-child(1) .culture-header p {
		color: #A6400A;
	}

	.culture-bet-left .culture:nth-child(1) .culture-header h5 {
		color: #A6400A;
	}

	.culture-bet-left .culture:nth-child(2) {
		background-color: #FFF6F3;
	}

	.culture-bet-left .culture:nth-child(2) p {
		color: #3A1903;
	}

	.culture-bet-left .culture:nth-child(2) .culture-header {
		background: linear-gradient(90deg, rgba(58, 25, 3, 0.1) 0%, #FFF6F3 100%);
	}

	.culture-bet-left .culture:nth-child(2) .culture-header p {
		color: #3A1903;
	}

	.culture-bet-left .culture:nth-child(2) .culture-header h5 {
		color: #3A1903;
	}

	.culture-bet-right .culture:nth-child(1) {
		background-color: #F7FBFF;
	}

	.culture-bet-right .culture:nth-child(1) p {
		color: #0057C2;
	}

	.culture-bet-right .culture:nth-child(1) .culture-header {
		background: linear-gradient(90deg, rgba(0, 87, 194, 0.1) 0%, #F1F7FF 100%);
	}

	.culture-bet-right .culture:nth-child(1) .culture-header p {
		color: #0057C2;
	}

	.culture-bet-right .culture:nth-child(1) .culture-header h5 {
		color: #0057C2;
	}

	.History-box {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		/* padding-bottom: 40px; */
	}

	.History-title {
		display: contents;
		margin-left: 0 !important;
		text-align: center;

	}

	.History-title h4 {
		color: #003A97;
		font-size: 36px;
		position: relative;
		left: 40px;
	}

	.History-title p {
		color: #666666;
		font-size: 30px;
	}

	.swiper-container-History {
		width: 90% !important;
		margin-top: 80px;
		margin-left: 5%;
		overflow: hidden;
	}

	.swiper-container-History .swiper-slide {
		/* margin: 0 40px; */
		display: flex;
		width: auto !important;
		/* flex-direction: column; */
		/* align-items: flex-end; */
		/* justify-content: flex-end; */
	}

	.swiper-container-History .swiper-slide .xian-left {
		height: auto;
		width: 15px;
		margin-right: 20px;
		height: auto;
	}

	.swiper-container-History .swiper-slide .xian-left span {
		width: 10px;
		height: 10px;
		border: 1px solid #003A97;
		border-radius: 50%;
		display: inline-block;
		position: relative;
	}
	.el-tabs__item{
		height: 60px;
		line-height: 60px;
	}
	.swiper-container-History .swiper-slide .xian-left p {
		width: 1.5px;
		height: calc(100% + 4px);
		background-color: #003A97;
		display: block;
		position: relative;
		left: 7px;
		top: -4px;
	}

	.swiper-container-History .swiper-slide img {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		width: 300px;
		height: 168px;
	}

	.History-title {
		width: 100%;
		/* text-align: right; */
	}

	.History-details-box {
		width: calc(100% - 32px);
	}
	.slide-box{
		display: flex;
		height: auto;
		
	}
	.History-details-box{
		margin: 0 auto;
	}
	.History-details-box img{
		margin-bottom: 20px;
	}
	.History-details-box p{
		width: 100%;
		line-height: 26px;
		text-align: left;
	}
	.History-details-box h4{
		font-size: 30px;
		color: #003A97;
		margin: 20px 0;
	}
	.swiper-container-History{
		height: auto;
	}
	.swiper-wrapper{
		align-items: flex-end;
	}
	.honor-list{
		 display: flex;
		    align-items: center;  /* 垂直居中 */
		    justify-content: center;  /* 水平居中 */
		    flex-wrap: wrap;
			margin-left:405px;
	}
	.honor{
		width: calc(45% - 20px);
		text-align: center;
		height: 450px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border: 1px solid rgba(0,0,0,0.1);
		margin-bottom: 30px;
	}
	.honor-list:after{
		content: '';
		width: calc(33.33% - 20px);
	 }
	.honor img{
		width: 349px;
		height: 246px;
	}
	.honor p{
		font-size: 16px;
		color: #303133;
		margin-top: 30px;
	}
	.page{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
		margin-bottom: 60px;
	}
</style>
