import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icon/iconfont.css'
import Swiper from "swiper"
import 'swiper/dist/css/swiper.css'
import axios from 'axios';
import http from "./utils/axios.js";
// import VueMeta from 'vue-meta';

Vue.prototype.$axios = http

Vue.use(ElementUI);
// import Component  from './utils/index'
// Vue.use(Component)
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
Vue.use(VueFullPage);
Vue.config.productionTip = false
document.title = '云南爱尔信集团';
// 监听路由变化
router.afterEach((to, from) => {
  document.title = to.meta.title || '云南爱尔信集团';
});
axios.defaults.baseURL = '/api'
new Vue({
  render: h => h(App),
  router
 
}).$mount('#app')


