<template>
	<div>
		<Header></Header>
		<div class="banna-box">
			<img src="../assets/image/banna-aex.png" />
			<div class="banna-title">
				<p>News</p>
				<span></span>
				<h4>业务详情</h4>
			</div>
		</div>
		<div class="content">
			<div class="container">
				<div class="new-det-box">
					<div class="new-det-title">
						<!-- <h5>{{detail.create_time}}</h5> -->
						<h4>{{detail.article_title}}</h4>
						<!-- <div class="new-det-source">
							<p>来源：{{detail.category.name}}</p>
							<p> 点击次数：{{detail.actual_views}}</p>
						</div> -->
					</div>
					<div class="new-det-text">
						<p v-html="detail.article_content"></p>
						<img :src="detail.image.file_path">
					</div>
					<!-- <div class="new-det-bottom">
						<div>
							<p>上一篇</p>
						</div>
						<div class="fanhui" @click="getNew">
							<p>返回列表</p>
						</div>
						<div>
							<p>下一篇</p>
						</div>
					</div> -->
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '../components/header.vue';
	import pageTab from '../components/pageTab.vue';
	import Footer from '../components/footer.vue';
	export default {
		components: {
			Header,
			Footer,
			pageTab
		},
		data() {
			return {
				active: '0',
				menus: '新闻中心',
				menuChildrens: '集团新闻',
        detail:[],
				tabLists: [{
						title: '集团新闻',
					},
					{
						title: '行业新闻',
					},
				],
			}
		},
    created(e) {
      //获取路由参数
      console.log(localStorage.getItem('det'),'param')

      this.$axios.get('article.Article/detail?article_id='+localStorage.getItem('det')).then(res => {
        this.detail = res.data.detail;
        console.log(this.detail,'this.detail')
      }).catch(error => {
        console.log(error, '请求失败');
      })
    },
		methods: {
			handleClick1(tab, event) {
				this.menuChildrens = tab.label;
				this.active = tab.index;
				console.log(this.active);
			},
			getNew(){
				this.$router.push({
					path:'/new'
				});
			}
		}
	}
</script>

<style>
	.new-det-box{
		background-color: #f5f5f5;
		margin: 30px 0;
	}
	.new-det-title{
		padding:30px 0 ;
		margin: 0 60px;
		border-bottom: 1px solid #e1e1e1;
		text-align: left;
	}
	.new-det-title h5{
		font-size: 30px;
		color: #999999;
		margin-bottom: 10px;
	}
	.new-det-title h4{
		font-size: 26px;
		color: #303133;
		line-height: 30px;
		margin-bottom: 15px;
	}
	.new-det-source {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.new-det-source p{
		font-size: 14px;
		color: #999;
		margin-right: 40px;
	}
	.new-det-text{
		padding:30px 0 ;
		margin: 0 60px;
	}
	.new-det-text p{
		font-size: 16px;
		color: #303133;
		line-height: 24px;
		text-indent: 2em;
		margin-bottom: 10px;
		text-align: left;
	}
	.new-det-text img{
		width: 100%;
		margin-top: 10px;
	}
	.new-det-bottom{
		border-top: 1px solid #e1e1e1;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 60px 0;
	}
	.new-det-bottom div{
		width: 206px;
		height: 45px;
		background-color: #F0F0F0;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 60px;
		cursor: pointer;
	}
	.new-det-bottom div p{
		font-size: 16px;
		color: #303133;
	}
	.new-det-bottom .fanhui{
		background-color: #003A97 !important;
		
	}
	.new-det-bottom .fanhui p{
		color: #fff !important;
	}
</style>
