<template>
	<div class="swiper-container-banna">
		<div class="swiper-wrapper">
			<div class="swiper-slide" v-for="item in bannaList">
				<a href="#">
					<img :src="item.banner_image" />
				</a>
			</div>
		</div>
		<!-- 如果需要分页器 -->
		<div class="swiper-pagination"></div>
		<!-- 如果需要导航按钮 --> 
		<div class="swiper-button-prev"></div>
		<div class="swiper-button-next"></div>
		<!-- 如果需要滚动条 -->
		<!-- <div class="swiper-scrollbar"></div> -->
	</div>
</template>


<script>
	export default {
		name: 'SwiperBanna',
		props: {
		  bannaList:{
			  type:Array,
			  default:()=>[]
		  }
		},
		data() {
			return { 
			}
		},
		created(){
			// console.log(this.bannaList);
		},
		mounted() {
			var swiper = new Swiper('.swiper-container-banna', {
				pagination: '.swiper-pagination', //分页器
				nextButton: '.swiper-button-next', //前进按钮
				prevButton: '.swiper-button-prev', //后退按钮
				loop: false, //循环
				paginationClickable: true, //分页器点击
				spaceBetween: 0, // swiper-slide 间的距离为30
				autoplay: 520000, //时长
				// autoplayDisableOnInteraction: false, //点击不会取消自动
			});
		}
	}
</script>

<style>
	.swiper-container-banna {
		/* width: 100%; */
		height: calc(100% - 82px);
		overflow: hidden;
	}
	.bannaList .swiper-container-banna a{
		width: 100%;
		display: inline-block;
	}
	.swiper-container-banna .swiper-slide{
		width: 100%;
		overflow: hidden;
	}
	.bannaList .swiper-container-banna a img{
		width: 100%;
		height: 100%;
	}
</style>
