<template>
	<div>
		<Header></Header>
		<div class="banna-box">
			<img src="../assets/image/banna-aex.png" />
			<div class="banna-title">
				<p>News</p>
				<span></span>
				<h4>新闻中心</h4>
			</div>
		</div>
		<div class="content">
			<div class="container">
				<pageTab @handleClick='handleClick1' :tabList='tabLists' :menu='menus' :menuChildren='menuChildrens'
					:activeName='active'>
				</pageTab>
			</div>
			<div class="new-box">
				<div class="container">
					<div class="business-list" v-for="(item,index) in businessList">
						<div class="business" v-if=" index%2==0" :id="['bus' + index]">
							<img :src="item.image.file_path" />
							<div class="business-det" @click="getNewDet(item.article_id)">
								<div class="datails-title">
									<!-- <h5>{{item.article_content}}</h5> -->
									<h4>{{item.article_title}}</h4>
								</div>
								<!-- <p>{{item.article_title}}</p> -->
								<div class="more" >
									<span>了解更多</span>
									<i class="iconfont icon-zhixiang-you"></i>
								</div>
							</div>
						</div>
						<div class="business" v-else :id="['bus' + index]">
							<div class="business-det"  @click="getNewDet(item.article_id)">
								<div class="datails-title">
									<!-- <h5>{{item.head}}</h5> -->
									<h4>{{item.article_title}}</h4>
								</div>
								<!-- <p>{{item.article_title}}</p> -->
								<div class="more">
									<span>了解更多</span>
									<i class="iconfont icon-zhixiang-you"></i>
								</div>
							</div>
							<img :src="item.image.file_path" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '../components/header.vue';
	import pageTab from '../components/pageTab.vue';
	import Footer from '../components/footer.vue';
	export default {
		components: {
			Header,
			Footer,
			pageTab
		},
		data() {
			return {
				active: '0',
				menus: '业务板块',
				menuChildrens: '财会板块',
				tabLists: [],
				busHeight:'616',
				busTop:'602',
				categoryId:'16',
				businessList: {}
			}
		},
		created(){
			this.getBusiness();
		},
		mounted(){
			window.addEventListener('scroll', this.handleScroll);
		},
		methods: {
			getBusiness(){
				this.$axios.get('/index.Index/professionalWork', {
					category_id: this.categoryId,
				}).then(res => {
					this.tabLists = res.data.category_list;
					this.businessList=res.data.datails
					console.log(this.tabLists);
				}).catch(error => {
					console.log(error, '请求失败');
				})
			},
			handleScroll(){
				var topScroll = document.documentElement.scrollTop;
				if(topScroll > this.busTop){
					var topBus = Math.round((topScroll - this.busTop) / this.busHeight) ;
				}else{
					return
				}
				if(topBus > 0){
					this.active = topBus.toString();
				}else{
					this.active = '0';
				}
			},
			handleClick1(tab, event) {
				console.log(tab)
				this.menuChildrens = tab.label;
				this.active = tab.index;
				this.categoryId = this.tabLists[tab.index].category_id;
				console.log(this.menuChildrens);
				console.log(this.categoryId);
				var PageId = document.querySelector("#bus" + tab.index);
				window.scrollTo({
					top: PageId.offsetTop,
					behavior: "smooth",
				});
			},
			getNewDet(id) {
				localStorage.setItem('det',id);
				this.$router.push({
					path: "/details",
				});
			}
		}
	}
</script>

<style>
	.datails-title {
		/* margin-top: 160px; */
		margin-bottom: 40px;
		padding-top: 60px;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
	}

	.datails-title h5 {
		font-size: 40px;
		color: #303133;
		opacity: 0.1;
		margin: 0;
	}

	.datails-title h4 {
		font-size: 24px;
		color: #303133;
		margin: 0;
		margin-top: -20px;
	}

	.new-list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.new {
		width: calc(33.33% - 20px);
		margin-bottom: 30px;
		background-color: #fff;
		cursor: pointer;
		position: relative;
		top: 0;
	}

	.new img {
		width: 100%;
		height: 288px;
	}

	.new-details {
		margin: 20px 15px;
		text-align: left;
	}

	.new-details p {
		font-size: 14px;
		color: #666;
	}

	.new-details h4 {
		color: #303133;
		font-size: 18px;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin: 10px 0;
		padding-bottom: 20px;
	}

	.new:hover {
		top: -2px;
		box-shadow: 0px 4px 8px 0px rgba(214, 215, 220, 0.5);
	}

	.new:hover h4 {
		color: #003A97;
	}

	.new-box {
		background-color: #fff;
	}

	.page {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
		padding-bottom: 60px;
		margin-bottom: 0 !important;
	}

	.business {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 20px 0;
		cursor: pointer;
		overflow: hidden;
	}

	.business img {
		width: 1024px;
		height: 576px;
	}

	.business-det {
		margin: 0 20px;
		text-align: left;
	}

	.business-det p {
		font-size: 16px;
		line-height: 24px;
		text-align: left;
	}

	.business .more {
		margin-top: 30px;
		color: #909199;
	}

	.business .more span {
		font-size: 14px;
		color: #909199;
		display: inline-block;
		margin-right: 20px;
	}
</style>
