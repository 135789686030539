<template>
	<div>
		<Header></Header>
		<div class="banna-box">
			<img src="../assets/image/banna-aex.png" />
			<div class="banna-title">
				<p>Talent recruitment</p>
				<span></span>
				<h4>人才招聘</h4>
			</div>
		</div>
		<div class="content">
			<div class="recruitment-box">
				<div class="container">
					<div class="datails-title">
						<h5>Talent recruitment</h5>
						<h4>人才招聘</h4>
					</div>
					<div class="recruitment-list">
						<div class="recruitment" v-for="(item,index) in recruitmentList" @click="getNewDet(item.job_id)">
							<div class="recruitment-details">
								<h4>{{item.name}}</h4>
								<div class="recruitment-map">
									<span v-if="item.department_id == 1">技术部</span>
									<span v-if="item.department_id == 4">技术部</span>
								</div>
								<p class="recruitment-content">{{item.content}}</p>
							</div>
							<div class="recruitment-bottom">
								<p>发布时间：{{item.create_time}}</p>
								<p>招聘人数：{{item.number}}</p>
							</div>
						</div>
					</div>
					<div class="page" v-show="pageShow">
						<el-pagination background layout="prev, pager, next" :total="pageTotal">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '../components/header.vue';
	import pageTab from '../components/pageTab.vue';
	import Footer from '../components/footer.vue';
	export default {
		components: {
			Header,
			Footer,
			pageTab
		},
		data() {
			return {
				active: '0',
				menus: '新闻中心',
				menuChildrens: '集团新闻',
				page:'1',
				pageSize: '9',
				tabLists: [{
						title: '集团新闻',
					},
					{
						title: '行业新闻',
					},
				],
				pageShow: false,
				pageTotal:'',
				recruitmentList: []
			}
		},
		created() {

			this.getWork();
		},
		methods: {
			getWork(parment) {
				this.$axios.get('/work.work/list', {
					per_page: this.pageSize,
					current_page: this.page,
				}).then(res => {
					if(res.data.list.last_page <= 1){
						this.pageShow = false
					}else{
						this.pageShow = true
					}
					this.pageTotal = res.data.list.total;
					this.recruitmentList = res.data.list.data;
				}).catch(error => {
					console.log(error, '请求失败');
				})
			},
			handleClick1(tab, event) {
				this.menuChildrens = tab.label;
				this.active = tab.index;
				console.log(this.active);
			},
			getNewDet() {
				this.$router.push({
					path: "/new-details",
				});
			}
		}
	}
</script>

<style>
	.datails-title {
		/* margin-top: 160px; */
		margin-bottom: 40px;
		padding-top: 60px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.datails-title h5 {
		font-size: 48px;
		color: #303133;
		opacity: 0.1;
		margin: 0;
	}

	.datails-title h4 {
		font-size: 30px;
		color: #303133;
		margin: 0;
		margin-top: -10px;
	}

	.recruitment-list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.recruitment-list::after{
		width: calc(33.33% - 20px);
		content: '';
	}
	.recruitment {
		width: calc(33.33% - 20px);
		margin-bottom: 30px;
		background-color: #fff;
		cursor: pointer;
		position: relative;
		top: 0;
		border: 1px solid #F7F7F7;
		border-radius: 2px;
	}

	.recruitment img {
		width: 100%;
		height: 288px;
	}

	.recruitment-details {
		margin: 30px 50px;
		text-align: left;
	}

	.recruitment-details p {
		font-size: 14px;
		color: #666;
		margin-bottom: 20px;
	}

	.recruitment-details h4 {
		color: #303133;
		font-size: 18px;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin: 10px 0;
		padding-bottom: 15px;
	}

	.recruitment-map {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 15px;
	}

	.recruitment-map span {
		margin-right: 20px;
	}

	.recruitment-bottom {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 30px 50px;
		border-top: 1px solid #f7f7f7;
	}

	.recruitment-bottom p {
		margin-right: 24px;
	}

	.recruitment:hover {
		top: -2px;
		box-shadow: 0px 4px 8px 0px rgba(214, 215, 220, 0.5);
		background-color: #003A97;
		color: #fff;
	}

	.recruitment:hover h4 {
		color: #fff;
	}
	.recruitment:hover .recruitment-details p  {
		color: #fff;
	}

	.recruitment-box {
		background-color: #fff;
	}
	.recruitment-content{
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.page {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
		padding-bottom: 60px;
		margin-bottom: 0 !important;
	}
</style>
