<template>
	<div>
		<Header></Header>
		<div class="banna-box">
			<img src="../assets/image/banna-aex.png" />
			<div class="banna-title">
				<p>News</p>
				<span></span>
				<h4>新闻中心</h4>
			</div>
		</div>
		<div class="content">
			<div class="container">
				<pageTab @handleClick='handleClick1' :tabList='tabLists' :menu='menus' :menuChildren='menuChildrens'
					:activeName='active'>
				</pageTab>
			</div>
			<div class="new-box">
				<div class="container">
					<div class="datails-title">
						<h5>News</h5>
						<h4>新闻中心</h4>
					</div>
					<div class="new-list">
						<div class="new" v-for="(item,index) in newList.data" @click="getNewDet(item.article_id)">
							<img :src="item.image.file_path" />
							<div class="new-details">
								<p>{{item.create_time}}</p>
								<h4>{{item.article_title}}</h4>
							</div>
						</div>
					</div>
					<div class="page">
						<el-pagination :current-page="page" background layout="prev, pager, next" @current-change="getPageNew"  :page-count="newList.last_page">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '../components/header.vue';
	import pageTab from '../components/pageTab.vue';
	import Footer from '../components/footer.vue';
	export default {
		components: {
			Header,
			Footer,
			pageTab
		},
		data() {
			return {
				active: '0',
				menus: '新闻中心',
				menuChildrens: '集团新闻',
				tabLists: [{
						title: '集团新闻',
					},
					{
						title: '行业新闻',
					},
				],
        page:1,
        pageSize:6,
				newList: [
				]
			}
		},
    created() {
      //获取路由参数
      console.log(localStorage.getItem('det'),'param')
      this.getNew()
    },
    methods: {
      getPageNew(e){
       this.page = e;
       this.getNew()
      },
      getNew(){
        this.$axios.get('/article.Article/newList', {
          per_page: this.pageSize,
          current_page: this.page,
        }).then(res => {
          this.tabLists = res.data.category_list;
          this.newList = res.data.datails;
          // console.log( newList.data)
          console.log( this.newList.data)
        }).catch(error => {
          console.log(error, '请求失败');
        })
      },
			handleClick1(tab, event) {
				this.menuChildrens = tab.label;
				this.active = tab.index;
				console.log(this.active);
			},
			getNewDet(id) {
        //存入缓存
				localStorage.setItem('det',id);

				this.$router.push({
					path: "/new-details",
				});
			}
		}
	}
</script>

<style>
	.datails-title {
		/* margin-top: 160px; */
		margin-bottom: 40px;
		padding-top: 60px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.datails-title h5 {
		font-size: 48px;
		color: #303133;
		opacity: 0.1;
		margin: 0;
	}

	.datails-title h4 {
		font-size: 30px;
		color: #303133;
		margin: 0;
		margin-top: -10px;
	}

	.new-list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.new {
		width: calc(33.33% - 20px);
		margin-bottom: 30px;
		background-color: #fff;
		cursor: pointer;
		position: relative;
		top: 0;
	}

	.new img {
		width: 100%;
		height: 288px;
	}

	.new-details {
		margin: 20px 15px;
		text-align: left;
	}

	.new-details p {
		font-size: 14px;
		color: #666;
	}

	.new-details h4 {
		color: #303133;
		font-size: 18px;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin: 10px 0;
		padding-bottom: 20px;
	}

	.new:hover {
		top: -2px;
		box-shadow: 0px 4px 8px 0px rgba(214, 215, 220, 0.5);
	}

	.new:hover h4 {
		color: #003A97;
	}

	.new-box {
		background-color: #f5f5f5;
	}

	.page {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
		padding-bottom: 60px;
		margin-bottom: 0 !important;
	}
</style>
