<template>
  <div id="app">
     <router-view/>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  // components: {
  //   // HelloWorld
  // }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.container{
	margin: 0 auto;
	width: 1600px;
}
body{
	margin: 0 auto;
}
h1,h2,h3,h4,h5,p{
	margin: 0;
}
.fade-leave-to {
  display: none !important;
}
</style>
