<template>
	<div class="header-box">
		<div class="container">
			<div class="header">
				<div class="logo">
					<img src="../assets/image/logo.png" />
				</div>
				<div class="nav-list">
					
					<div class="menu" v-for="(ite,index1) in headList" :class="menuIndex == index1 ? 'menuActive' :''" @click="getMenu(index1)">
						<h4>{{ite.name}}</h4>
						<p class="xian"></p>
					</div>
					<div class="serach">
						<i class="el-icon-search"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Header',
		data() {
			return {
				activeIndex: '0',
				menuIndex:0,
				head: [{
					category_id: 0,
					name: "首页"
				}],
				headList: [],
			}
		},
		created() {
			this.getHeader();
			this.headList = JSON.parse(localStorage.getItem('headList1'));
			this.menuIndex = localStorage.getItem('active');
		},
		methods: {
			getHeader() {
				this.$axios.get('/article.Article/category', {
				}).then(res => {
					let headLists =[];
					headLists = this.head.concat(res.data.category);
					localStorage.setItem('headList1', JSON.stringify(headLists));
				}).catch(error => {
					console.log(error, '请求失败');
				})
			},
			getMenu(index) {
				localStorage.setItem('active', index);
				if(index == 0){
					this.$router.push({
						path: "/home",
					});
				}else if(index == 1){
					this.$router.push({
						path: "/intr",
					});
				}else if(index == 2){
					this.$router.push({
						path: "/new",
					});
				}else if(index == 3){
					this.$router.push({
						path: "/business",
					});
				}else if(index == 4){
					// this.$router.push({
					// 	path: "/hemo",
					// });
				}else if(index == 5){
					this.$router.push({
						path: "/recruitment",
					});
				}else if(index == 6){
					this.$router.push({
						path: "/hemo",
					});
				}else if(index == 7){
					this.$router.push({
						path: "/contact",
					});
				}
				
			}
		}
	}
</script>

<style>
	a {
		text-decoration: none !important;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 82px;
	}

	.logo img {
		width: 191px;
		height: 50px;
	}

	.el-menu.el-menu--horizontal {
		border-bottom: none !important;
	}

	.nav-list {
		display: flex;
		align-items: center !important;
		justify-content: flex-start !important;
	}

	.nav-list i {
		position: relative;
		margin-left: 10px;
		/* top: 20px; */
	}
	.menu{
		margin: 0 15px;
		cursor: pointer;
	}
	.menu h4{
		font-size: 15px;
		color: #909199;
	}
	.menu p{
		height: 2px;
		width: 100%;
		background-color: transparent;
		position: relative;
		top: 10px;
	}
	.menuActive p{
		background-color: #409EFF !important;
	}
</style>
